import {Box} from '@mui/material';
import React from 'react';
import {useMediaQuery} from 'react-responsive';
// import { Container } from './styles';

const Body = () => {
  const isSmall = useMediaQuery({query: '(max-width: 750.98px)'});
  const isVerySmall = useMediaQuery({query: '(max-width:450.00px)'});

  return (
    <div style={{width: '100%', height: 'auto'}}>
      <div style={{background: '#ffffff'}}>
        <Box
          boxShadow={3}
          borderRadius={1}
          style={{
            backgroundColor: '#ffffff',
            marginTop: 60,
            marginLeft: isVerySmall || isSmall ? 10 : 30,
            marginRight: isVerySmall || isSmall ? 10 : 30,
            padding: isVerySmall || isSmall ? 15 : 20,
          }}
        >
          <div>
            <h3>I. Relacionamento Contratual</h3>
            <p>
              Estes termos de uso (“Termos”) regem seu acesso e uso, como pessoa
              física, dentro do Brasil, de aplicativos, sites de Internet,
              conteúdos, bens e também serviços (os “Serviços”) disponibilizados
              pela Irê it, estabelecida no Brasil, com sede na Avenida Central
              Braulio C. Viana, nº. 322, Terreo, CEP 44905-000, Lapão/BA,
              inscrita no Cadastro Nacional de Pessoas Jurídicas do Ministério
              da Fazenda (CNPJ/MF) sob n. 38.095.254/0001-03 (“Irê it”) ou
              qualquer de suas afiliadas.
            </p>
            <br />
            <p>
              POR FAVOR, LEIA COM ATENÇÃO ESTES TERMOS ANTES DE ACESSAR OU USAR
              OS SERVIÇOS.
            </p>
            <br />
            <p>
              Ao acessar e usar os Serviços você concorda com os presentes
              termos e condições, que estabelecem o relacionamento contratual
              entre você, na qualidade de usuário(a), e a Irê it. Se você não
              concorda com estes Termos, você não pode acessar nem usar os
              Serviços. Mediante referido acesso e uso, estes Termos
              imediatamente encerram, substituem e superam todos os acordos,
              Termos e acertos anteriores entre você e qualquer Afiliada da Irê
              it. A Irê it poderá imediatamente encerrar estes Termos ou
              quaisquer Serviços em relação a você ou, de modo geral, deixar de
              oferecer ou negar acesso aos Serviços ou a qualquer parte deles, a
              qualquer momento e por qualquer motivo.
            </p>
            <br />
            <p>
              Termos adicionais poderão se aplicar a determinados Serviços, tais
              como condições para um evento, atividade ou promoção em
              particular, e esses termos adicionais serão divulgados em relação
              aos respectivos Serviços. Termos adicionais são complementares e
              considerados parte integrante destes Termos para os efeitos dos
              respectivos Serviços. Termos adicionais prevalecerão sobre estes
              Termos em caso de conflito com relação aos referidos Serviços.
            </p>
            <br />
            <p>
              A Irê it poderá alterar os Termos relativos aos Serviços a
              qualquer momento. Aditamentos entrarão em vigor quando a Irê it
              fizer a postagem da versão atualizada dos Termos neste local ou
              das condições atualizadas ou Termos adicionais sobre o respectivo
              Serviço. O fato de você continuar a acessar ou usar os Serviços
              após essa postagem representa seu consentimento em vincular-se aos
              Termos alterados.
            </p>
            <br />
            <p>
              A Irê it é a controladora dos dados pessoais coletados em conexão
              com o uso dos Serviços no Brasil. Os seus dados pessoais são
              transferidos para os Estados Unidos da América com base em
              mecanismos aprovados sob as leis aplicáveis, e a coleta e
              utilização pela Irê it de dados pessoais associados aos Serviços
              são feitas de acordo com o Aviso de Privacidade da Irê it, que
              pode ser consultada em
              www.ireit.com.br/politica_de_privacidade.html.
            </p>
            <br />
            <p>Última alteração: 06 de janeiro 2025</p>
            <p>Entrada em vigor: 06 de janeiro de 2025</p>
          </div>
        </Box>
        <Box
          boxShadow={3}
          borderRadius={1}
          style={{
            backgroundColor: '#ffffff',
            marginTop: 60,
            marginLeft: isVerySmall || isSmall ? 10 : 30,
            marginRight: isVerySmall || isSmall ? 10 : 30,
            padding: isVerySmall || isSmall ? 15 : 20,
          }}
        >
          <div className="post-content">
            <h3>II. Os serviços</h3>
            <p>
              Os Serviços integram uma plataforma de tecnologia que permite
              aos(às) Usuários(as) de aplicativos móveis ou sites de Internet da
              Irê it, fornecidos como parte dos Serviços (cada qual um
              “Aplicativo”), solicitar e programar serviços de transporte e/ou
              logística e/ou compra de certos bens e/ou entrega de produtos com
              terceiros independentes provedores desses serviços, inclusive
              terceiros independentes fornecedores de transporte, terceiros
              independentes fornecedores de logística, terceiros independentes
              fornecedores de bens e terceiros independentes responsáveis por
              realizar entregas de produtos, mediante contrato com a Irê it ou
              com determinadas afiliadas da Irê it (“Parceiros Independentes”).
              Os serviços de entrega (delivery) abrangem a intermediação entre
              os Usuários(as), estabelecimentos parceiros e entregadores
              independentes, para que produtos adquiridos em estabelecimentos
              parceiros sejam entregues no endereço especificado pelo(a)
              Usuário(a). A Irê it não garante a qualidade, integridade ou
              conformidade dos produtos entregues, sendo tais responsabilidades
              exclusivas dos estabelecimentos parceiros. Além disso, a
              responsabilidade pela realização das entregas e pelo cumprimento
              dos prazos estabelecidos cabe exclusivamente aos Parceiros
              Independentes responsáveis por esse serviço. A menos que
              diversamente acordado pela Irê it em contrato escrito celebrado em
              separado com você, os Serviços são disponibilizados para seu uso
              pessoal e não comercial. VOCÊ RECONHECE QUE A IRÊ IT NÃO É
              FORNECEDORA DE BENS, NÃO PRESTA SERVIÇOS DE TRANSPORTE, LOGÍSTICA
              OU DELIVERY, NEM FUNCIONA COMO TRANSPORTADORA OU PRESTADORA DE
              SERVIÇOS DE ENTREGA, E QUE TODOS ESSES SERVIÇOS SÃO PRESTADOS POR
              PARCEIROS INDEPENDENTES, QUE NÃO SÃO EMPREGADOS(AS) E NEM
              REPRESENTANTES DA IRÊ IT, NEM DE QUALQUER DE SUAS AFILIADAS.
            </p>
            <h3>Relação com os Parceiros Independentes</h3>
            <p>
              Antes de utilizar a plataforma, é essencial que você leia
              atentamente estes Termos e Condições. Não há qualquer vínculo
              empregatício, direto ou indireto, entre a Irê it, suas afiliadas
              ou qualquer pessoa jurídica relacionada, e os(as) entregadores(as)
              parceiros(as). A Consolidação das Leis do Trabalho (CLT) não se
              aplica à relação entre a Irê it ou suas afiliadas e os(as)
              entregadores(as), considerando a natureza autônoma da prestação de
              serviços. Os(as) entregadores(as) têm total autonomia para decidir
              quando, onde e por quanto tempo utilizarão a plataforma de
              entrega. A Irê it não determina horários, rotas ou metas
              obrigatórias, reforçando a independência e liberdade dos(as)
              parceiros(as) para atuarem conforme sua conveniência. Ao se
              cadastrar e continuar utilizando os serviços oferecidos pela
              plataforma, o(a) entregador(a) declara que leu, compreendeu e
              aceitou integralmente os Termos e Condições aqui apresentados.
              Caso, em algum momento, não concorde com estes Termos, o(a)
              entregador(a) deverá cessar imediatamente a utilização da
              plataforma e desinstalá-la de seu dispositivo. Estes Termos e
              Condições substituem expressamente quaisquer acordos, contratos ou
              entendimentos anteriores, sejam eles verbais ou escritos, entre a
              Irê it e os(as) entregadores(as). Qualquer atualização ou
              modificação será devidamente comunicada e estará sujeita à
              aceitação continuada pelo uso dos serviços.
            </p>
            <br />
            <h3>Licença.</h3>
            <p>
              Sujeito ao cumprimento destes Termos, a Irê it outorga a você uma
              licença limitada, não exclusiva, não passível de sublicença,
              revogável e não transferível para: (i) acesso e uso dos
              Aplicativos em seu dispositivo pessoal, exclusivamente para o seu
              uso dos Serviços; e (ii) acesso e uso de qualquer conteúdo,
              informação e material correlato que possa ser disponibilizado por
              meio dos Serviços, em cada caso, para seu uso pessoal, nunca
              comercial. Quaisquer direitos não expressamente outorgados por
              estes Termos são reservados à Irê it e suas afiliadas
              licenciadoras.
            </p>
            <br />
            <h3>Restrições.</h3>
            <p>
              Você não poderá: (i) remover qualquer aviso de direito autoral,
              direito de marca ou outro aviso de direito de propriedade de
              qualquer parte dos Serviços; (ii) reproduzir, modificar, preparar
              obras derivadas, distribuir, licenciar, locar, vender, revender,
              transferir, exibir, veicular, transmitir ou, de qualquer outro
              modo, explorar os Serviços, exceto da forma expressamente
              permitida pela Irê it; (iii) decompilar, realizar engenharia
              reversa ou desmontar os Serviços, exceto conforme permitido pela
              legislação aplicável; (iv) conectar, espelhar ou recortar qualquer
              parte dos Serviços; (v) fazer ou lançar quaisquer programas ou
              scripts com a finalidade de fazer scraping, indexação, pesquisa ou
              qualquer outra forma de obtenção de dados de qualquer parte dos
              Serviços, ou de sobrecarregar ou prejudicar indevidamente a
              operação e/ou funcionalidade de qualquer aspecto dos Serviços; ou
              (vi) tentar obter acesso não autorizado aos Serviços ou prejudicar
              qualquer aspecto dos Serviços ou seus sistemas ou redes
              correlatas.{' '}
            </p>
            <br />
            <h3>Prestação dos Serviços.</h3>
            <p>
              Você reconhece que os Serviços podem ser disponibilizados sob
              diferentes marcas da Irê it ou diferentes opções de solicitação
              associadas aos serviços de transporte, logística e delivery
              prestados por Parceiros Independentes. Essas marcas incluem, mas
              não se limitam a: “Irê it Basic”, “Irê it TAXI”, “Irê it
              MOTOTAXI”, “Irê it ELAS”; “Irê it ENTREGA”; “Irê it MOTOBOY”, “Irê
              it BIKE”, “Irê it SPACE” e “Irê it DELIVERY”, entre outras. Além
              disso, você reconhece que os Serviços podem estar disponíveis sob
              essas marcas e opções de solicitação oferecidas por, ou associadas
              a: (i) determinadas subsidiárias e afiliadas da Irê it; ou (ii)
              Parceiros Independentes, incluindo motoristas e entregadores
              autônomos, detentores de permissões ou licenças necessárias para a
              prestação desses serviços. No caso específico do serviço “Irê it
              DELIVERY” / “Irê it MME”, aplicam-se os seguintes termos para os
              entregadores parceiros:
            </p>

            <h4>Direitos e Obrigações dos Entregadores(as) Parceiros(as)</h4>
            <p>
              1. **Autonomia Operacional**: Os(as) entregadores(as) que utilizam
              a plataforma Irê it DELIVERY para aceitar solicitações de entregas
              têm total liberdade para determinar sua jornada de trabalho, os
              dias e horários em que desejam se conectar à plataforma, bem como
              as entregas que irão aceitar ou recusar, respeitando as condições
              gerais de uso.
            </p>
            <p>
              2. **Responsabilidade pela Prestação do Serviço**: O(a)
              entregador(a) é responsável por garantir que as entregas sejam
              realizadas com qualidade, segurança e dentro dos prazos
              estipulados nas solicitações aceitas. Isso inclui a conservação e
              transporte adequado dos produtos, conforme as orientações de cada
              solicitação.
            </p>
            <p>
              3. **Condições de Uso da Plataforma**: Para utilizar a plataforma,
              o(a) entregador(a) deve possuir os equipamentos necessários,
              incluindo dispositivo móvel compatível e meio de transporte
              adequado, e garantir que estejam em boas condições de uso. Todos
              os custos relacionados à prestação do serviço (como manutenção do
              veículo, combustível e plano de dados móveis) são de
              responsabilidade exclusiva do(a) entregador(a).
            </p>
            <p>
              4. **Ausência de Vínculo Empregatício**: A relação entre a Irê it
              e os(as) entregadores(as) é estritamente comercial,
              configurando-se como uma parceria entre partes independentes. Não
              há vínculo empregatício, subordinação ou exclusividade, sendo o(a)
              entregador(a) livre para utilizar outras plataformas ou prestar
              serviços fora do escopo da Irê it.
            </p>
            <p>
              5. **Aprovação e Cadastro**: Para atuar como entregador(a)
              parceiro(a), é necessário realizar cadastro prévio na plataforma
              Irê it, fornecendo documentos válidos que comprovem identidade,
              habilitação para conduzir (quando aplicável) e regularidade do
              veículo utilizado. A aprovação está sujeita à análise e validação
              pela equipe da Irê it.
            </p>
            <p>
              6. **Suspensão e Bloqueio**: A Irê it reserva-se o direito de
              suspender ou bloquear o acesso do(a) entregador(a) à plataforma em
              casos de descumprimento dos Termos de Uso, prática de atos
              ilícitos, má conduta com clientes ou uso inadequado da plataforma.
              A suspensão será previamente comunicada, salvo em casos graves que
              demandem ação imediata.
            </p>
            <p>
              7. **Relação com os Clientes**: Os(as) entregadores(as) reconhecem
              que são os únicos responsáveis pela interação direta com os
              clientes finais, sendo necessário agir de forma cortês,
              profissional e ética durante o atendimento.
            </p>
            <p>
              8. **Independência na Precificação**: Os valores pagos pelos
              serviços realizados são definidos com base nas diretrizes e
              algoritmos da plataforma, que consideram fatores como distância,
              tempo estimado e outros critérios objetivos. O(a) entregador(a)
              parceiro(a) é remunerado(a) de acordo com essas regras, e
              eventuais questionamentos deverão ser tratados diretamente com a
              equipe de suporte da Irê it.
            </p>
            <p>
              Ao aceitar os termos e utilizar os serviços da Irê it DELIVERY /
              Irê it MME, o(a) entregador(a) parceiro(a) declara ciência e
              concordância com as regras acima estabelecidas, reafirmando seu
              compromisso com a qualidade e excelência no atendimento.
            </p>

            <br />
            <h3>Serviços e Conteúdo de Terceiros(as).</h3>
            <p>
              Os Serviços poderão ser disponibilizados e acessados em conexão
              com Serviços e conteúdo de terceiros(as) (inclusive publicidade)
              que a Irê it não controlará. VOCÊ RECONHECE QUE TERMOS DE USO E
              POLÍTICAS DE PRIVACIDADE DIFERENTES PODERÃO SER APLICÁVEIS AO USO
              DESSES SERVIÇOS E CONTEÚDO DE TERCEIROS(AS). A IRÊ IT NÃO ENDOSSA
              ESSES SERVIÇOS E CONTEÚDO DE TERCEIROS(AS) E A IRÊ IT NÃO SERÁ, EM
              HIPÓTESE ALGUMA, RESPONSÁVEL POR NENHUM PRODUTO OU SERVIÇO
              DESSES(AS) TERCEIROS(AS) FORNECEDORES(AS). Além disto, Apple Inc.,
              Google, Inc., Microsoft Corporation ou BlackBerry Limited e/ou
              suas subsidiárias e afiliadas internacionais serão terceiros(as)
              beneficiários(as) deste Contrato, caso você acesse os Serviços
              usando aplicativos desenvolvidos para dispositivos móveis baseados
              em Apple iOS, Android, Microsoft Windows, ou Blackberry,
              respectivamente. Esses(as) terceiros(as) beneficiários(as) não são
              partes deste Contrato e não são responsáveis pela prestação dos
              Serviços ou por qualquer forma de suporte aos Serviços. Seu acesso
              aos Serviços usando esses dispositivos está sujeito às condições
              estabelecidas nos termos de serviços dos respectivos terceiros(as)
              beneficiários(as).
            </p>
            <br />
            <h3>Titularidade.</h3>
            <p>
              Os Serviços e todos os direitos sobre eles são e permanecerão de
              propriedade da Irê it ou de propriedade das afiliadas da Irê it,
              ou de suas respectivas licenciadoras, conforme o caso. Estes
              Termos e o uso dos Serviços não lhe outorgam nem lhe conferem
              qualquer direito: (i) sobre os Serviços, exceto pela licença
              limitada concedida acima; ou (ii) de usar ou, de qualquer modo,
              fazer referência a nomes societários, logotipos, nomes de produtos
              ou de Serviços, marcas comerciais ou marcas de serviço da Irê it
              ou de qualquer licenciadora da Irê it.
            </p>
          </div>
        </Box>
        <Box
          boxShadow={3}
          borderRadius={1}
          style={{
            backgroundColor: '#ffffff',
            marginTop: 60,
            marginLeft: isVerySmall || isSmall ? 10 : 30,
            marginRight: isVerySmall || isSmall ? 10 : 30,
            padding: isVerySmall || isSmall ? 15 : 20,
          }}
        >
          <div>
            <h3>III. Uso dos Serviços</h3>
            <h3>Contas de Usuários.</h3>
            <p>
              Para utilizar grande parte dos Serviços, você deve registrar-se e
              manter uma conta pessoal de usuário de Serviços (“Conta”). Você
              deve ter pelo menos 18 anos para registrar uma Conta. Usuários com
              idade igual ou superior a 12 anos poderão registrar-se e manter
              uma Conta desde que tenham sido devidamente representados ou
              tenham obtido o consentimento de seu(s) responsável(is) legal(is),
              conforme o procedimento para registro aplicável em cada caso. O
              registro de Conta exige que a Irê it colete determinados dados
              pessoais, que podem incluir seu nome, endereço, número de telefone
              celular e data de nascimento, assim como pelo menos um método de
              pagamento válido (cartão de crédito ou parceiro de pagamento
              aceito pela Irê it). Você concorda em manter informações corretas,
              completas e atualizadas em sua Conta. Se você não mantiver
              informações corretas, completas e atualizadas em sua Conta,
              inclusive se o método de pagamento informado for inválido ou
              expirado, você poderá ficar impossibilitado(a) de acessar e usar
              os Serviços ou a Irê it poderá resolver estes Termos. Você é
              responsável por todas as atividades realizadas na sua Conta e
              concorda em manter sempre a segurança e confidencialidade do nome
              de usuário e senha da sua Conta. A menos que diversamente
              permitido pela Irê it por escrito, você poderá manter apenas uma
              Conta.
            </p>
            <br />
            <h3>Conduta e Obrigações do Usuário.</h3>
            <p>
              Você não poderá autorizar terceiros(as) a usar sua Conta ou
              receber serviços de transporte ou logística dos Parceiros
              Independentes, salvo se estiverem em sua companhia. Você não
              poderá ceder, nem de qualquer outro modo transferir, sua Conta a
              nenhuma outra pessoa ou entidade. Você concorda em cumprir todas
              as leis aplicáveis quando usar os Serviços e que somente poderá
              usar os Serviços para finalidades legítimas (por ex. não
              transportar materiais ilegais ou perigosos). Você não poderá,
              quando usar os Serviços, causar transtorno, aborrecimento,
              inconveniente ou danos à propriedade dos Parceiros Independentes
              ou de qualquer outro terceiro. Em determinadas situações, você
              poderá ser solicitado(a) a fornecer comprovante de identidade para
              acessar ou usar os Serviços, e concorda que poderá ter seu acesso
              ou uso dos Serviços negado caso você se recuse a fornecer um
              comprovante de identidade.
            </p>
            <br />
            <h3>Mensagem de Texto (SMS).</h3>
            <p>
              Ao criar uma Conta, e tal como estabelecido na Política de
              Privacidade da Irê it, você concorda que os Serviços poderão lhe
              enviar mensagens de textos (SMS) como parte das operações
              comerciais regulares para o uso dos Serviços. Você poderá optar
              por não receber mensagens de texto (SMS) da Irê it a qualquer
              momento, seguindo as instruções indicadas em
              www.ireit.com.br/sms/. Você reconhece que optar por não receber as
              mensagens de texto poderá prejudicar o seu uso dos Serviços.
            </p>
            <br />
            <h3>Códigos Promocionais.</h3>
            <p>
              A Irê it poderá, a seu exclusivo critério, criar códigos
              promocionais que poderão ser resgatados para crédito na Conta ou
              outras características ou benefícios relacionados aos Serviços
              e/ou a serviços de Parceiros Independentes, sujeitos a quaisquer
              condições adicionais que a Irê it estabelecer para cada um dos
              códigos promocionais (“Códigos Promocionais”). Você concorda que
              Códigos Promocionais: (i) devem ser usados de forma legal para a
              finalidade e o público a que se destinam; (ii) não devem ser
              duplicados, de qualquer forma vendidos, transferidos ou
              disponibilizados ao público em geral (seja por meio de postagem ao
              público ou qualquer outro método), a menos que expressamente
              permitido pela Irê it; (iii) poderão ser desabilitados pela Irê it
              a qualquer momento por motivos legalmente legítimos, sem que disto
              resulte qualquer responsabilidade para a Irê it; (iv) somente
              poderão ser usados de acordo com as condições específicas que a
              Irê it estabelecer para esses Código Promocional; (v) não são
              válidos como dinheiro; e (vi) poderão expirar antes de serem
              usados. A Irê it se reserva o direito de reter ou deduzir créditos
              ou outras funcionalidades ou vantagens obtidas por meio do uso dos
              Códigos Promocionais por você ou por outro usuário, caso a Irê it
              apure ou acredite que o uso ou resgate do Código Promocional foi
              feito com erro, fraude, ilegalidade ou violação às condições do
              respectivo Código Promocional.
            </p>
            <br />
            <h3>Conteúdo Fornecido pelo(a) Usuário(a).</h3>
            <p>
              A Irê it poderá, a seu exclusivo critério, permitir que você ou
              qualquer pessoa apresente, carregue, publique ou, de qualquer
              modo, disponibilize para a Irê it por meio dos Serviços, conteúdo
              e informações de texto, áudio ou vídeo, inclusive comentários e
              feedbacks relacionados aos Serviços, iniciação de solicitação de
              suporte e registro em concursos e promoções (“Conteúdo de
              Usuário(a)”). Qualquer Conteúdo de Usuário(a) fornecido por você
              permanece de sua propriedade. Contudo, ao fornecer Conteúdo de
              Usuário(a) para a Irê it, você outorga a Irê it e suas afiliadas
              uma licença dos direitos autorais sobre o Conteúdo de Usuário em
              nível mundial, perpétua, irrevogável, transferível, isenta de
              royalties, e com direito a sublicenciar, usar, copiar, modificar,
              criar obras derivadas, distribuir, publicar, exibir, executar em
              público e, de qualquer outro modo, explorar esse Conteúdo de
              Usuário(a) em todos os formatos e canais de distribuição hoje
              conhecidos ou desenvolvidos no futuro (inclusive em conexão com os
              Serviços e com os negócios da Irê it e em sites e Serviços de
              terceiros), sem ulterior aviso a você ou seu consentimento, e sem
              necessidade de pagamento a você ou a qualquer outra pessoa ou
              entidade.
            </p>
            <br />
            <p>
              Você declara e garante que: (i) é o(a) único(a) e exclusivo(a)
              proprietário(a) de todo Conteúdo de Usuário(a) ou tem todos os
              direitos, licenças, autorizações e permissões necessários para
              outorgar à Irê it a licença sobre o Conteúdo de Usuário(a) acima
              referido; e (ii) nem o Conteúdo de Usuário(a) nem sua
              apresentação, carregamento, publicação ou outra forma de
              disponibilização desse Conteúdo de Usuário(a), tampouco o uso do
              Conteúdo de Usuário(a) pela Irê it da forma aqui permitida,
              infringirão, constituirão apropriação indevida nem violarão
              propriedade intelectual ou direito de propriedade de terceiros(a),
              nem direitos de publicidade ou privacidade e também não resultarão
              na violação de qualquer lei ou regulamento aplicável.
            </p>
            <br />
            <p>
              Você concorda em não fornecer Conteúdo de Usuário(a) que seja
              difamatório, calunioso, injurioso, violento, obsceno,
              pornográfico, ilegal ou de qualquer modo ofensivo, conforme
              apuração da Irê it a seu critério exclusivo, seja ou não esse
              material protegido por lei. A Irê it poderá, mas não está obrigada
              a, analisar, monitorar ou remover Conteúdo de Usuário(a), a
              critério exclusivo da Irê it, a qualquer momento e por qualquer
              motivo, sem nenhum aviso a você.
            </p>
            <br />
            <h3>Acesso à Rede e Equipamentos.</h3>
            <p>
              Você é responsável por obter o acesso a rede de dados necessário
              para usar os Serviços. As taxas e encargos de sua rede de dados e
              mensagens poderão se aplicar se você acessar ou usar os Serviços
              de um dispositivo sem fio e você será responsável por essas taxas
              e encargos. Você é responsável por adquirir e atualizar os
              equipamentos e dispositivos necessários para acessar e usar os
              Serviços e Aplicativos e quaisquer de suas atualizações. A IRê IT
              NÃO GARANTE QUE OS SERVIÇOS, OU QUALQUER PARTE DELES, FUNCIONARÃO
              EM QUALQUER EQUIPAMENTO OU DISPOSITIVO EM PARTICULAR. Além disso,
              os Serviços poderão estar sujeitos a mau funcionamento e atrasos
              inerentes ao uso da Internet e de comunicações eletrônicas.
            </p>
            <br />
          </div>
        </Box>
        <Box
          boxShadow={3}
          borderRadius={1}
          style={{
            backgroundColor: '#ffffff',
            marginTop: 60,
            marginLeft: isVerySmall || isSmall ? 10 : 30,
            marginRight: isVerySmall || isSmall ? 10 : 30,
            padding: isVerySmall || isSmall ? 15 : 20,
          }}
        >
          <div>
            <h3>IV. Pagamento</h3>
            <p>
              Você entende que os serviços ou bens que você receber de um
              Parceiro Independente, contratados por meio dos Serviços, poderão
              ser cobrados (“Preço”). Após você ter recebido serviços ou bens
              obtidos por meio do uso do Serviço, a Irê it facilitará o seu
              pagamento do respectivo Preço ao Parceiro Independente, agindo na
              qualidade de agente limitado de cobrança do Parceiro Independente.
              O pagamento do Preço feito dessa maneira será considerado
              pagamento feito diretamente por você ao Parceiro Independente. O
              preço incluirá todos os tributos exigidos por lei. Por favor
              visite https://www.ireit.com.br/cidades.html para obter
              informações adicionais sobre uma determinada região.
            </p>
            <br />
            <p>
              O preço pago por você é final e não reembolsável, a menos que
              diversamente determinado pela Irê it. Você tem o direito de
              solicitar uma redução no Preço ao Parceiro Independente por
              serviços ou bens recebidos desse Parceiro Independente no momento
              em que receber esses serviços ou bens. A Irê it responderá de
              acordo com qualquer solicitação de Parceiro Independente para
              modificar o Preço de um serviço ou bem em particular. Na máxima
              extensão permitida pela lei aplicável, a Irê it reserva o direito
              de limitar os Preços cobrados em espécie. Uma vez limitado o valor
              a ser pago em espécie, você deverá disponibilizar um método
              alternativo de pagamento. Nas hipóteses em que o pagamento em
              espécie for um método de pagamento aceito, você poderá escolher
              receber eventuais valores devidos pelo Parceiro Independente a
              título de troco na forma de créditos Irê it Cash, que poderão ser
              utilizados para pagamento em novas solicitações de Serviços.
            </p>
            <br />
            <p>
              O preço total é devido e deve ser pago imediatamente após a
              prestação do serviço ou entrega do bem pelo Parceiro Independente
              e o pagamento será facilitado pela Irê it mediante o método de
              pagamento indicado na sua Conta, após o que a Irê it enviará um
              recibo por e-mail. Se for verificado que o método de pagamento
              indicado na Conta expirou, é inválido ou não pode ser cobrado,
              você concorda que a Irê it poderá, na qualidade de agente limitado
              de cobrança do Parceiro Independente, usar um método secundário de
              cobrança na Conta, se houver.
            </p>
            <br />
            <p>
              Na relação entre você e a Irê it, a Irê it reserva-se o direito de
              estabelecer, remover e/ou revisar o Preço relativo a todos os
              serviços ou bens obtidos por meio do uso dos Serviços a qualquer
              momento, a critério exclusivo da Irê it. Ademais, você reconhece e
              concorda que o Preço aplicável em certas áreas geográficas poderá
              (i) incluir cobranças, tarifas, taxas, impostos e/ou contribuições
              governamentais devidas em seu nome, inclusive, tarifas de pedágios
              ou cobranças de aeroportos, conforme a rota tomada pelo Parceiro
              Independente, o local de origem e/ou destino da viagem ou, e
              legislação aplicável e, (ii) aumentar substancialmente quando a
              oferta de serviços por parte dos Parceiros Independentes for menor
              do que a demanda por referidos serviços. O pagamento de taxas,
              impostos e/ou contribuições governamentais, serão de sua
              responsabilidade e você reembolsará o Parceiro Independente e/ou a
              Irê it por todas tarifas, taxas, impostos e/ou contribuições
              governamentais pagas em seu nome. Para mais detalhes sobre aumento
              de Preço em decorrência de aumento substancial da demanda, clique
              aqui. A Irê it envidará esforços razoáveis para informá-lo dos
              Preços que poderão se aplicar, sendo certo que você será
              responsável pelo pagamento dos Preços lançados em sua Conta
              independentemente de estar ciente desses Preços ou de seus
              valores. A Irê it poderá, a qualquer momento, fornecer a
              certos(as) usuários(as) ofertas e descontos promocionais que
              poderão resultar em valores diferentes cobrados por Serviços
              iguais ou similares a outros obtidos por meio do uso dos Serviços,
              e você concorda que essas ofertas e descontos promocionais, a
              menos que também oferecidos a você, não terão influência sobre os
              Preços aplicados. Você poderá optar por cancelar sua solicitação
              de serviços ou bens de um Parceiro Independente a qualquer momento
              antes da chegada desse Parceiro Independente, caso em que poderá
              incidir uma taxa de cancelamento.
            </p>
            <br />
            <p>
              Esta estrutura de pagamento se destina a remunerar integralmente
              os Parceiros Independentes pelos serviços ou bens disponibilizados
              a você. A Irê it não indica nenhuma parcela do pagamento como
              valor extra ou gratificação aos Parceiros Independentes. Qualquer
              declaração da Irê it (no website da Irê it, no Aplicativo ou nos
              materiais de marketing da Irê it) de que o pagamento de valores
              extras são “voluntários”, “não obrigatórios” e/ou “não incluídos”
              no pagamento que você faz pelos serviços ou bens prestados não
              pretende sugerir que a Irê it oferece valores adicionais àqueles
              descritos acima aos Parceiros Independentes. Você compreende e
              concorda que, embora você seja livre para fazer pagamentos
              adicionais como valor extra a quaisquer Parceiros Independentes
              que forneça serviços ou bens por meio dos Serviços, você não tem
              obrigação de fazê-lo. Valores extras são de caráter voluntário.
              Após ter recebido os serviços ou bens obtidos por meio dos
              Serviços, você terá a oportunidade de avaliar sua experiência e
              fazer comentários adicionais sobre o Parceiro Independente.
            </p>
            <br />
            <h3>Taxa de Reparos ou Limpeza.</h3>
            <p>
              Você será responsável pelos custos de reparos a danos ou pela
              limpeza de veículos de Parceiros Independentes resultantes do uso
              dos Serviços por meio da sua Conta que excedam os danos naturais
              decorrentes do uso (“Reparos ou Limpeza”). Caso um Parceiro
              Independente relate a necessidade de Reparos ou Limpeza e essa
              solicitação de Reparos ou Limpeza seja confirmada pela Irê it, a
              critério razoável da Irê it, a Irê it reserva-se o direito de
              facilitar o pagamento desses Reparos ou Limpeza em nome do
              Parceiro Independente usando o método de pagamento indicado em sua
              Conta. Referidos valores serão transferidos pela Irê it ao
              respectivo Parceiro Independente e não serão reembolsáveis.
            </p>
            <br />
          </div>
        </Box>
        <Box
          boxShadow={3}
          borderRadius={1}
          style={{
            backgroundColor: '#ffffff',
            marginTop: 60,
            marginLeft: isVerySmall || isSmall ? 10 : 30,
            marginRight: isVerySmall || isSmall ? 10 : 30,
            padding: isVerySmall || isSmall ? 15 : 20,
          }}
        >
          <div>
            <h3>
              V. Recusa de Garantia; Limitação de Responsabilidade; Indenização.
            </h3>
            <h3>Recusa de Garantia.</h3>
            <p>
              OS SERVIÇOS SÃO PRESTADOS “NO ESTADO” E “COMO DISPONÍVEIS”. A IRÊ
              IT RECUSA TODAS AS DECLARAÇÕES E GARANTIAS, EXPRESSAS, IMPLÍCITAS
              OU LEGAIS, NÃO EXPRESSAMENTE CONTIDAS NESTES TERMOS, INCLUSIVE AS
              GARANTIAS IMPLÍCITAS DE COMERCIALIZAÇÃO, ADEQUAÇÃO A UMA
              FINALIDADE ESPECÍFICA E NÃO INFRINGÊNCIA. ADEMAIS, A IRÊ IT NÃO
              FAZ NENHUMA DECLARAÇÃO NEM DÁ GARANTIA SOBRE A CONFIABILIDADE,
              PONTUALIDADE, QUALIDADE, ADEQUAÇÃO OU DISPONIBILIDADE DOS SERVIÇOS
              OU DE QUAISQUER SERVIÇOS OU BENS SOLICITADOS POR MEIO DO USO DOS
              SERVIÇOS, NEM QUE OS SERVIÇOS SERÃO ININTERRUPTOS OU LIVRES DE
              ERROS. A IRÊ IT NÃO GARANTE A QUALIDADE, ADEQUAÇÃO, SEGURANÇA OU
              HABILIDADE DE PARCEIROS INDEPENDENTES. VOCÊ CONCORDA QUE TODO O
              RISCO DECORRENTE DO USO DOS SERVIÇOS E DE QUALQUER SERVIÇO OU BEM
              SOLICITADO POR MEIO DA TECNOLOGIA SERÁ SEMPRE SEU NA MÁXIMA MEDIDA
              PERMITIDA PELA LEI APLICÁVEL.
            </p>
            <br />
            <h3>Limitação de Responsabilidade.</h3>
            <br />
            <p>
              A IRÊ IT NÃO SERÁ RESPONSÁVEL POR DANOS INDIRETOS, INCIDENTAIS,
              ESPECIAIS, PUNITIVOS OU EMERGENTES, INCLUSIVE LUCROS CESSANTES,
              PERDA DE DADOS, DANOS MORAIS OU PATRIMONIAIS RELACIONADOS,
              ASSOCIADOS OU DECORRENTES DE QUALQUER USO DOS SERVIÇOS AINDA QUE A
              IRÊ IT TENHA SIDO ALERTADA PARA A POSSIBILIDADE DESSES DANOS. A
              IRÊ IT NÃO SERÁ RESPONSÁVEL POR NENHUM DANO, OBRIGAÇÃO OU PREJUÍZO
              DECORRENTE DO: (i) SEU USO DOS SERVIÇOS OU SUA INCAPACIDADE DE
              ACESSAR OU USAR OS SERVIÇOS; OU (ii) QUALQUER OPERAÇÃO OU
              RELACIONAMENTO ENTRE VOCÊ E QUALQUER PARCEIRO INDEPENDENTE, AINDA
              QUE A IRÊ IT TENHA SIDO ALERTADA PARA A POSSIBILIDADE DESSES
              DANOS. A IRÊ IT NÃO SERÁ RESPONSÁVEL POR ATRASOS OU FALHAS
              DECORRENTES DE CAUSAS FORA DO CONTROLE RAZOÁVEL DA IRÊ IT E,
              TAMPOUCO, PELA QUALIDADE E INTEGRIDADE DOS BENS DISPONIBILIZADOS
              POR PARCEIROS INDEPENDENTES. VOCÊ RECONHECE QUE PARCEIROS
              INDEPENDENTES QUE PRESTAREM SERVIÇOS DE TRANSPORTE SOLICITADOS POR
              MEIO DE ALGUMAS MARCAS PODERÃO OFERECER SERVIÇOS DE TRANSPORTE DO
              TIPO “DIVISÃO DE VIAGEM” (RIDESHARING) OU PONTO A PONTO
              (PEER-TO-PEER).
            </p>
            <br />
            <p>
              OS SERVIÇOS DA IRÊ IT PODERÃO SER USADOS POR VOCÊ PARA SOLICITAR E
              PROGRAMAR SERVIÇOS DE TRANSPORTE, BENS OU LOGÍSTICA PRESTADOS POR
              PARCEIROS INDEPENDENTES, MAS VOCÊ CONCORDA QUE A IRÊ IT NÃO TEM
              RESPONSABILIDADE EM RELAÇÃO A VOCÊ, POR CONTA DE QUALQUER SERVIÇO
              DE TRANSPORTE, BENS OU LOGÍSTICA REALIZADOS POR PARCEIROS
              INDEPENDENTES, SALVO SE EXPRESSAMENTE ESTABELECIDA NESTES TERMOS.
              COMO CONSEQUÊNCIA, A IRÊ IT NÃO TEM QUALQUER RESPONSABILIDADE POR
              ROTAS ADOTADAS POR PARCEIROS INDEPENDENTES OU POR QUAISQUER ITENS
              PERDIDOS NOS VEÍCULOS DE PARCEIROS INDEPENDENTES.
            </p>
            <br />
            <p>
              AS LIMITAÇÕES E RECUSA DE GARANTIAS CONTIDAS NESTA CLÁUSULA 5 NÃO
              POSSUEM O OBJETIVO DE LIMITAR RESPONSABILIDADES OU ALTERAR
              DIREITOS DE CONSUMIDOR QUE, DE ACORDO COM A LEI APLICÁVEL, NÃO
              PODEM SER LIMITADOS OU ALTERADOS.
            </p>
            <br />
            <h3>Seguro de Acidentes Pessoais.</h3>
            <p>
              VOCÊ RECONHECE QUE A IRÊ IT MANTÉM SEGURO DE ACIDENTES PESSOAIS DE
              PASSAGEIROS (APP), COM COBERTURA, NOS TERMOS E LIMITES DA APÓLICE,
              PARA REEMBOLSO DE DESPESAS MÉDICO-HOSPITALARES, INVALIDEZ
              PERMANENTE TOTAL OU PARCIAL POR ACIDENTE E MORTE ACIDENTAL, DESDE
              QUE EM VIAGEM REGULAR PELA PLATAFORMA, E QUE EVENTUAL PAGAMENTO DE
              INDENIZAÇÃO PELA SEGURADORA A VOCÊ SERÁ FEITO SEM QUALQUER
              ASSUNÇÃO DE RESPONSABILIDADE PELA IRÊ IT E SERÁ DEDUZIDO DE
              QUALQUER EVENTUAL FUTURA OUTRA INDENIZAÇÃO RECLAMADA DECORRENTE
              DAQUELE MESMO INCIDENTE.
            </p>
            <br />
            <h3>Indenização.</h3>
            <p>
              Você concorda em indenizar e manter a Irê it, seus diretores(as),
              conselheiros(as), empregados(as) e agentes isentos(as) de
              responsabilidade por todas e quaisquer reclamações, cobranças,
              prejuízos, responsabilidades e despesas (inclusive honorários
              advocatícios) decorrentes ou relacionados: (i) ao uso dos
              Serviços, de serviços ou bens obtidos por meio do uso dos
              Serviços; (ii) descumprimento ou violação de qualquer disposição
              destes Termos; (iii) o uso, pela Irê it, do Conteúdo de
              Usuário(a); ou (iv) violação dos direitos de terceiros, inclusive
              Parceiros Independentes.
            </p>
            <br />
          </div>
        </Box>
        <Box
          boxShadow={3}
          borderRadius={1}
          style={{
            backgroundColor: '#ffffff',
            marginTop: 60,
            marginLeft: isVerySmall || isSmall ? 10 : 30,
            marginRight: isVerySmall || isSmall ? 10 : 30,
            padding: isVerySmall || isSmall ? 15 : 20,
          }}
        >
          <div>
            <h3>VI. SOLUÇÃO DE CONTROVÉRSIAS</h3>
            <p>
              A Irê it disponibiliza uma estrutura de atendimento, composta por
              ferramentas de contato e uma equipe especializada de suporte para
              o tratamento de reclamações com o objetivo de evitar a
              judicialização de controvérsias que, eventualmente, possam surgir
              com o seu uso dos Serviços.
            </p>
            <br />
            <p>
              Diante disso, você se compromete a utilizar as ferramentas de
              suporte disponibilizadas pela Irê it como primeiro meio para a
              solução de controvérsias decorrentes do seu uso dos Serviços.
            </p>
            <br />
            <p>
              Você também poderá buscar a solução de controvérsias por meio do
              serviço www.consumidor.gov.br, mantido pela Secretaria Nacional do
              Consumidor (SENACON) do Ministério da Justiça e Segurança Pública,
              que é disponibilizado gratuitamente aos consumidores de todo o
              país com o objetivo de prevenir e reduzir a quantidade de
              controvérsias judicializadas.
            </p>
            <br />
          </div>
        </Box>
        <Box
          boxShadow={3}
          borderRadius={1}
          style={{
            backgroundColor: '#ffffff',
            marginTop: 60,
            marginLeft: isVerySmall || isSmall ? 10 : 30,
            marginRight: isVerySmall || isSmall ? 10 : 30,
            padding: isVerySmall || isSmall ? 15 : 20,
          }}
        >
          <div className="post-content">
            <h3>VII. Legislação Aplicável; Jurisdição.</h3>
            <br />
            <p>
              Estes Termos serão regidos e interpretados exclusivamente de
              acordo com as leis do Brasil. Qualquer reclamação, conflito ou
              controvérsia que surgir deste contrato ou a ele relacionada,
              inclusive que diga respeito a sua validade, interpretação ou
              exequibilidade, será solucionada exclusivamente pelos tribunais do
              foro de seu domicílio.
            </p>
            <br />
          </div>
        </Box>
        <Box
          boxShadow={3}
          borderRadius={1}
          style={{
            backgroundColor: '#ffffff',
            marginTop: 60,
            marginLeft: isVerySmall || isSmall ? 10 : 30,
            marginRight: isVerySmall || isSmall ? 10 : 30,
            padding: isVerySmall || isSmall ? 15 : 20,
            marginBottom: 40,
          }}
        >
          <div>
            <h3>VIII. Outras Disposições</h3>
            <h3>Alegações de Violação de Direito Autoral.</h3>

            <p>
              Alegações de violação de direito autoral devem ser encaminhadas ao
              representante indicado pela Irê it. Consulte a página de Internet
              da Irê it em https://www.ireit.com.br/legal para obter o endereço
              indicado e outras informações.
            </p>
            <br />
            <h3>Avisos.</h3>

            <p>
              A Irê it poderá enviar avisos por meio de notificações gerais nos
              Serviços, correio eletrônico para seu endereço de e-mail em sua
              Conta, ou por comunicação escrita enviada ao endereço indicado em
              sua Conta. Você poderá notificar a Irê it por meio do Aplicativo,
              comunicação pelo endereço eletrônico suporte@ireit.com.br ou fazer
              comunicação escrita para o endereço da Irê it na Avenida Central
              Braulio C. Viana, nº. 322, Terreo, CEP 44905-000, Lapão/BA,
              Brasil.
            </p>
            <br />
            <h3>Disposições Gerais.</h3>

            <p>
              Você não poderá ceder, nem tampouco transferir estes Termos, total
              ou parcialmente, sem prévia aprovação por escrito da Irê it. Você
              concorda que a Irê it ceda e transfira estes Termos, total ou
              parcialmente, inclusive: (i) para uma subsidiária ou afiliada;
              (ii) um adquirente das participações acionárias, negócios ou bens
              da Irê it; ou (iii) para um sucessor em razão de qualquer operação
              societária. Não existe joint-venture, sociedade, emprego ou
              relação de representação entre você, a Irê it ou quaisquer
              Parceiros Independentes como resultado do contrato entre você e a
              Irê it ou pelo uso dos Serviços.
            </p>
            <br />
            <p>
              Caso qualquer disposição destes Termos seja tida como ilegal,
              inválida ou inexequível total ou parcialmente, por qualquer
              legislação, essa disposição ou parte dela será, naquela medida,
              considerada como não existente para os efeitos destes Termos, mas
              a legalidade, validade e exequibilidade das demais disposições
              contidas nestes Termos não serão afetadas. Nesse caso, as partes
              substituirão a disposição ilegal, inválida ou inexequível, ou
              parte dela, por outra que seja legal, válida e exequível e que, na
              máxima medida possível, tenha efeito similar à disposição tida
              como ilegal, inválida ou inexequível para fins de conteúdo e
              finalidade dos presentes Termos. Estes Termos constituem a
              totalidade do acordo e entendimento das partes sobre este assunto
              e substituem e prevalecem sobre todos os entendimentos e
              compromissos anteriores sobre este assunto. Nestes Termos, as
              palavras “inclusive” e “inclui” significam “incluindo, sem
              limitação”.
            </p>
            <br />
          </div>
        </Box>
      </div>
    </div>
  );
};

export default Body;
